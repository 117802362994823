import axios from 'axios';
import constants from './../../constants';
import { Dispatch } from 'react';
import Cliente from '../../classes/cliente';

let sessionTimeout: ReturnType<typeof setTimeout>;

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const RESET_AUTH_STATE = 'RESET_AUTH_STATE';

export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';

export const INVALID_USER = 'INVALID_USER';

export const loginStart = () => ({ type: LOGIN_START });
export const loginError = () => ({ type: LOGIN_ERROR });
export const loginSuccess = (user: Cliente, token: string) => ({ type: LOGIN_SUCCESS, token, user });

export const forgotPasswordStart = () => ({ type: FORGOT_PASSWORD_START });
export const forgotPasswordError = () => ({ type: FORGOT_PASSWORD_ERROR });
export const forgotPasswordSuccess = () => ({ type: FORGOT_PASSWORD_SUCCESS });

export const invalidUser = (reason: string) => ({ type: INVALID_USER, reason });
export const resetAuthState = () => {
    clearTimeout(sessionTimeout);
    localStorage.removeItem('rthMagAuth');
    return { type: RESET_AUTH_STATE };
};

const checkAuthTimeout = (expiresIn: number) => (dispatch: Dispatch<any>) => {
    sessionTimeout = setTimeout(() => {
        alert('Su sesión ha expirado. Por favor, ingrese sus credenciales nuevamente.');
        dispatch(resetAuthState());
    }, expiresIn);
};

export const authenticate = ({ 
    credentials = {}, 
    loginAction = false 
} = {}) => {
    return (dispatch: Dispatch<any>) => {
        dispatch(resetAuthState());
        dispatch(loginStart());

        return axios.post(`${constants.server}/${loginAction ? 'login' : 'signup'}`, credentials)
            .then(response => handleAuth(response.data, dispatch))
            .catch(() => dispatch(loginError()));
    };
};

export const isValidUser = (): boolean => {
    const { expirationDate, token } = JSON.parse(localStorage.getItem('rthMagAuth') || '{}');
    const expiresIn = expirationDate ? expirationDate - new Date().getTime() : 0;
    return token !== '' && expiresIn > 0;
};

export const tryAutoLogin = () => (dispatch: Dispatch<any>) => {
    if (isValidUser()) {
        const { client, expirationDate, token } = JSON.parse(localStorage.getItem('rthMagAuth') || '{}');
        const expiresIn = expirationDate ? expirationDate - new Date().getTime() : 0;
        dispatch(loginSuccess(client, token));
        dispatch(checkAuthTimeout(expiresIn));
        return;
    }

    dispatch(resetAuthState());
};

export const resetPassword = (token: string, password: string) => {
    return (dispatch: Dispatch<any>) => {
        dispatch(forgotPasswordStart());

        const headers = { headers: { 'Authorization': `Bearer ${token}` } };
        return axios.put(`${constants.server}/reset-password`, { PASSWORD: password }, headers)
            .then(response => handleAuth(response.data, dispatch))
            .catch(() => dispatch(forgotPasswordError()));
    };
};

export const requestPasswordReset = (credentials: any) => (dispatch: Dispatch<any>) => {
    dispatch(forgotPasswordStart());

    return axios.put(`${constants.server}/request-password-reset`, credentials)
        .then(() => dispatch(forgotPasswordSuccess()))
        .catch(() => dispatch(forgotPasswordError()));
};

export const getHeaders = () => {
    const { token } = JSON.parse(localStorage.getItem('rthMagAuth') || '{}');
    
    if (token) {
        return { 'Authorization': `Bearer ${token}` };
    }
};

const handleAuth = (data: any, dispatch: Dispatch<any>) => {
    const { client, err, expiresIn, token, validUser } = data;

    if (!validUser) {
        dispatch(invalidUser(err));
        return;
    }

    const expirationDate = new Date().getTime() + expiresIn;
    localStorage.setItem('rthMagAuth', JSON.stringify({ client, expirationDate, token }));

    dispatch(loginSuccess(client, token));
    dispatch(checkAuthTimeout(expiresIn));
};