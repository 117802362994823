import React from 'react';
import './Slider.scss';

const Slider: React.FunctionComponent<{}> = React.memo(() => (
    <div>
        <div 
            id="magSliderControls" 
            className="Slider carousel slide" 
            data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img 
                        className="d-block w-100" 
                        src="./assets/slider/1.jpg" 
                        alt="RTH"
                    />
                    <div className="carousel-caption d-none d-md-block SliderCaption">
                        <p>La más amplia gama de productos para el sector <b>automotriz, agro transporte e industria.</b></p>
                    </div>
                </div>
            
                <div className="carousel-item">
                    <img 
                        className="d-block w-100" 
                        src="./assets/slider/2.jpg" 
                        alt="RTH"
                    />
                    <div className="carousel-caption d-none d-md-block SliderCaption">
                        <p>Distribuidor mayorista de repuestos y herramientas</p>
                    </div>
                </div>
            
                <div className="carousel-item">
                    <img 
                        className="d-block w-100" 
                        src="./assets/slider/3.jpg" 
                        alt="RTH"
                    />
                    <div className="carousel-caption d-none d-md-block SliderCaption">
                        <p>Brindando soluciones a nuestros clientes desde 1980</p>
                    </div>
                </div>
            </div>
    
            <a 
                className="carousel-control-prev" 
                href="#magSliderControls" 
                role="button" 
                data-slide="prev">
                <span 
                    className="carousel-control-prev-icon" 
                    aria-hidden="true">
                </span>
                <span className="sr-only">Anterior</span>
            </a>
            <a 
                className="carousel-control-next" 
                href="#magSliderControls" 
                role="button" 
                data-slide="next">
                <span 
                    className="carousel-control-next-icon" 
                    aria-hidden="true">
                </span>
                <span className="sr-only">Siguiente</span>
            </a>
        </div>
        <div className="SliderBar">
            <div className="SliderBlueBar"></div>
            <div className="SliderYellowBar"></div>
        </div>
    </div>
));

export default Slider;