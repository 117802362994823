import React from 'react';
import classNames from 'classnames';
import './Input.scss';

export interface InputMap {
    change?: any,
    className?: any
    maxLength?: number,
    minLength?: number,
    type: string,
    placeholder?: string,
    required?: boolean,
    id: any,
    value?: number|string,
    keyPress?: any
};

const Input: React.FunctionComponent<InputMap> = React.memo((props: InputMap) => {
    const { type, id, placeholder, required, minLength, maxLength, change, className, value, keyPress } = props;
    return (
        <input 
            className={classNames('Mag-Input', className)}
            type={type}
            id={id}
            placeholder={placeholder}
            required={required}
            minLength={minLength}
            maxLength={maxLength}
            onChange={change} 
            onKeyPress={keyPress}
            value={value}
        />
    );
});

export default Input;