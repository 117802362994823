import React from 'react';
import './Devoluciones.scss';
import { Helmet } from "react-helmet";
const Devoluciones: React.FC<{}> = React.memo(() => (
    <div className="container Devoluciones">
        <Helmet>
            <title>RTH | Devoluciones</title>
            <meta name="description" content="Distribuidor mayorista de respuestos y herramientas. Dedicados al agro, transporte, industria y herramientas. Repuesto automotor" />
            <meta name="keywords" content="distribuidora, traverso, rth, junin, ropa de trabajo, 3m, lubricantes y aditivos, filtros, correas, bombas de agua, baterias, herramientas, lamparas, mangueras y abrazaderas, depositos, termostatos, argentina, indumentaria, automotor, accesorios, distribucion, repuestos, automotriz, automotor, transporte, agro, industria, servicio, ricardo traverso, distribuidor mayorista" />
        </Helmet>
        <h3 className="SectionTitle">Política de garantía</h3>
        <h4 className="SubTitle">Devolución</h4>
        <div className="separator"></div>
        <ul>
            <li>Sólo se aceptarán mercaderías que se encuentren en su envase original y sin faltante de sus accesorios.</li>
            <li>El embalaje se deberá encontrar en perfecto estado, sin manchas, roturas y/o escrituras.</li>
            <li>No se aceptarán productos con algún indicio de colocación.</li>
            <li>Sólo se aceptarán devoluciones dentro de los treinta (30) días de la factura.</li>
            <li>El flete será a cargo del cliente, salvo devoluciones justificadas.</li>
            <li>No se admitirán devoluciones de arículos pedidos por encargo, salvo fallas del producto.</li>
            <li>No se admitirán reclamos si la cuenta se encuentra en mora.</li>
        </ul>

        <h4 className="SubTitle">Garantía por fallas</h4>
        <div className="separator"></div>
        <ul>
            <li>En todos los casos el cliente deberá describir por escrito la falla del producto con su número de factura y fecha de compra.</li>
            <li>No se aceptarán garantías de productos mal instalados o empleados fuera de las especificaciones del fabricante.</li>
            <li>No se aceptarán productos que traen talón de garantía y este no estuviere.</li>
            <li>Los artículos que así lo requieran serán enviados a fábrica, siendo estos los que darán una respuesta definitiva al reclamo.</li>
            <li>Los productos importados no tienen garantía.</li>
            <li>Una vez recibido el producto en la empresa, se procederá a analizar el reclamo para su posterior cambio o nota de crédito, en los casos de comprobación de falla o defectos de fabricación.</li>
            <li>En casos de ser rechazada la garantía, la mercadería se pondrá a disposición del cliente.</li>
            <li>La garantía solamente cubre las piezas reconocidas como defectuosas, sin aceptar responsabilidades sobre terceros. En ningún caso la responsabilidad excederá el precio de compra del producto.</li>
            <li>No se admitirán reclamos si la cuenta se encuentra en mora.</li>
        </ul>
    </div>
));

export default Devoluciones;