import React from 'react';
import Logo from './../Logo/Logo';
import NavList, { NavListItemMap } from '../NavList/NavList';
import './CommonNav.scss';

const CommonNav: React.FunctionComponent<{}> = React.memo(() => {
    const navItems: NavListItemMap[] = [
        { activeClassName: 'active-route', className: '', exact: true, label: 'Inicio', to: '/' },
        { activeClassName: 'active-route', className: '', exact: true, label: 'Empresa', to: '/empresa' },
        { activeClassName: 'active-route', className: '', exact: true, label: 'Contacto', to: '/contacto' }
    ];

    return (
        <div className="container CommonNavContainer">
            <Logo className="float-left" />

            <div className="float-right">
                <NavList items={navItems} className="CommonNavLinks" />
            </div>
        </div>
    );
});

export default CommonNav;