import React from "react";
import "./Footer.scss";

const Footer: React.FunctionComponent<{}> = React.memo(() => {
  return (
    <footer className="FooterContainer">
      <div className="MainFooterContainer">
        <div className="container">
          <div className="row LogosContainer">
            <div className="col-md-6 col-sm-6">
              <span className="SmallIcon FooterLogo"></span>
            </div>
            <div className="col-md-6 col-sm-6 SocialMediaContainer text-right">
              <span className="SmallIcon FbIcon"></span>
              <span className="SmallIcon InstagramIcon"></span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <p>
                <span className="SmallIcon EmailIcon"></span>
                <a href="mailto:ventas@rthrepuestos.com.ar">
                  ventas@rthrepuestos.com.ar
                </a>
              </p>
              <p>
                <span className="SmallIcon PhoneIcon"></span> (236) 442-9978
              </p>
              <p>
                <span className="SmallIcon WpIcon"></span> 236-458-6393
              </p>
              <p>
                <span className="SmallIcon GpsIcon"></span>{" "}
                <b>Av. Dr. Benito de Miguel 875</b> (6000) Junín - Buenos Aires
              </p>
              <p>
                <span className="SmallIcon TimeIcon"></span> Lun. a Vie. 8 a 12:30hs y 15:00 a 19hs | Sáb 8:00 a 13:00hs
              </p>
            </div>
            <div className="col-md-6">
              <a
                href="http://qr.afip.gob.ar/?qr=f0eg3BEUe7hzq2ulWrsvPg,,"
                target="_F960AFIPInfo"
              >
                <img
                  src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg"
                  className="DataFiscalIcon"
                  alt="Data discal"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="SubfooterContainer">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              RICARDO TRAVERSO E HIJO SRL. | 2020
            </div>
            <div className="col-md-6 col-sm-6 text-right">
              <a
                href="http://huskysoftware.com.ar"
                target="_blank"
                rel="noopener noreferrer"
              >
                Desarrollo <span className="husky-logo"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
});

export default Footer;
