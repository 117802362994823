import React from 'react';
import './Catalogos.scss';
import { Helmet } from "react-helmet";

const Catalogos: React.FC<{}> = React.memo(() => {
    const path: string = './assets/catalogos';
    const catalogo: any[] = [
        { img: `${path}/1.jpg`, title: '3M', link: null },
        { img: `${path}/2.jpg`, title: 'CHIVILCOY BOMBAS', link: 'http://www.chivilcoybombas.com.ar/catalogo.html' },
        { img: `${path}/3.jpg`, title: 'CONTINENTAL (CATALOGO WEB)', link: 'https://aam-southamerica.contitech.de/pages/web-katalog/web-katalog_es.cshtml' },
        { img: `${path}/3.jpg`, title: 'CONTINENTAL (AUTOMOTOR)', link: 'http://rthrepuestos.com.ar/catcontitech.xls' },
        { img: `${path}/3.jpg`, title: 'CONTINENTAL (VARIADOR)', link: 'http://rthrepuestos.com.ar/catvariador.pdf ' },
        { img: `${path}/3.jpg`, title: 'CONTINENTAL (MULTIBANDA)', link: 'http://rthrepuestos.com.ar/catmultibanda.pdf' },
        { img: `${path}/5.jpg`, title: 'EUROTECH', link: 'https://eurotechdavidson.com/book/index.php?catalogo=EUROTECH' },
        { img: `${path}/4.jpg`, title: 'DAVIDSON', link: 'https://eurotechdavidson.com/book/index.php?catalogo=DAVIDSON' },
        { img: `${path}/dze.jpg`, title: 'DZE', link: 'http://dze.com.ar/es/' },
        { img: `${path}/6.jpg`, title: 'FLEETGUARD', link: 'https://catalog.cumminsfiltration.com/catalog/' },
        { img: `${path}/7.jpg`, title: 'FLORIO', link: 'http://rthrepuestos.com.ar/catflorionap.pdf' },
        { img: `${path}/8.jpg`, title: 'HAMILTON', link: 'http://www.hamilton.com.ar/archivos/catalogo/Hamilton-catalogo-sd2019.pdf' },
        { img: `${path}/9.jpg`, title: 'HANKOOK', link: null },
        { img: `${path}/10.jpg`, title: 'HESCHER', link: 'https://www.hescher.com.ar/catalogos/' },
        { img: `${path}/11.jpg`, title: 'INA', link: 'http://rthrepuestos.com.ar/catalogoina.xlsx' },
        { img: `${path}/12.jpg`, title: 'JARAMA', link: 'http://jarama.com.ar/industrial_mantenimientoylimpieza.html' },
        { img: `${path}/13.jpg`, title: 'KUGELLAGER', link: 'http://www.kugellager.com.ar/catalogo/' },
        { img: `${path}/14.jpg`, title: 'LANSS', link: 'http://www.lanss.com.ar/productos.html' },
        { img: `${path}/15.jpg`, title: 'LUBER FINER', link: 'https://luberfiner.com/' },
        { img: `${path}/16.jpg`, title: 'MATEO', link: 'https://www.bateriasmateo.com.ar/' },
        { img: `${path}/17.jpg`, title: 'MOHER', link: 'http://www.moher.com.ar/' },
        { img: `${path}/18.jpg`, title: 'NAP', link: 'http://rthrepuestos.com.ar/catflorionap.pdf' },
        { img: `${path}/19.jpg`, title: 'NEOLUX', link: 'https://www.neolux-lighting.com/media/ressources/neolux-catalogue/files/assets/basic-html/page-1.html' },
        { img: `${path}/20.jpg`, title: 'NGK', link: 'http://www.ngkntk.com.br/automotivo/wp-content/uploads/2019/07/TABLA_ARG_2019_baja-1.pdf' },
        { img: `${path}/21.jpg`, title: 'OSRAM', link: 'https://www.osram.de/apps/gvlrg/en_COM' },
        { img: `${path}/22.jpg`, title: 'PERFECTO', link: 'http://www.abrazaderas.net/linea-abrazadera/' },
        { img: `${path}/23.jpg`, title: 'PETRONAS', link: 'http://rthrepuestos.com.ar/catapetronas.pdf' },
        { img: `${path}/23.jpg`, title: 'PETRONAS (FICHAS TECNICAS)', link: 'http://rthrepuestos.com.ar/fichastecpet.rar' },
        { img: `${path}/rey-goma.jpg`, title: 'REY GOMA', link: 'https://reygoma.com.ar/' },
        { img: `${path}/ronbay.png`, title: 'RON BAY', link: 'https://fusiblesronbay.com.ar/' },
        { img: `${path}/24.jpg`, title: 'ROPA DE TRABAJO', link: 'http://rthrepuestos.com.ar/catropycal.pdf' },
        { img: `${path}/28.jpg`, title: 'SAKURA', link: 'http://rthrepuestos.com.ar/catasakura.pdf' },
        { img: `${path}/29.jpg`, title: 'TECNECO', link: 'http://rthrepuestos.com.ar/catatecneco.pdf' },
        { img: `${path}/29.jpg`, title: 'TECNECO (KIT FILTROS)', link: 'http://rthrepuestos.com.ar/catteckit.pdf' },
        { img: `${path}/30.jpg`, title: 'TECNOCOM', link: 'https://www.pvctecnocom.com.ar/index.php/tubos-flexibles' },
        { img: `${path}/31.jpg`, title: 'TRIBUNO', link: 'https://www.tribunohome.com/' },
        // { img: `${path}/.jpg`, title: 'VARIOS (ACCESORIOS, CABLES, PRECINTOS)', link: 'http://rthrepuestos.com.ar/cataccesorios.xlsx' },
        { img: `${path}/32.jpg`, title: 'WHIZ', link: 'https://www.whiz.com.ar/#' },
        { img: `${path}/33.jpg`, title: 'WYNN´S', link: 'https://wynns.com.ar/tienda/' },
        { img: `${path}/34.jpg`, title: 'YACO', link: 'http://yaco-sa.com/catalogos/' },
        { img: `${path}/34.jpg`, title: 'YACO (CATALOGO MANGUERAS)', link: 'http://rthrepuestos.com.ar/catmanyaco.pdf' }
    ];

    return (
        <div className="container Catalogos">
            <Helmet>
                <title>RTH | Catálogos</title>
                <meta name="description" content="Distribuidor mayorista de respuestos y herramientas. Dedicados al agro, transporte, industria y herramientas. Repuesto automotor" />
                <meta name="keywords" content="distribuidora, traverso, rth, junin, ropa de trabajo, 3m, lubricantes y aditivos, filtros, correas, bombas de agua, baterias, herramientas, lamparas, mangueras y abrazaderas, depositos, termostatos, argentina, indumentaria, automotor, accesorios, distribucion, repuestos, automotriz, automotor, transporte, agro, industria, servicio, ricardo traverso, distribuidor mayorista" />
            </Helmet>
            <h3 className="SectionTitle">Catálogos</h3>
            <div className="row">
                {catalogo.map((c: any, index: number) => (
                    <div
                        key={index} 
                        className="col-md-3 MarcaContainer">
                        <div className="ImageContainer">
                            <img src={c.img} alt="" />
                        </div>
                        <a href={c.link} target="_blank" rel="noopener noreferrer">{c.title}</a>
                    </div>
                ))}
            </div>
        </div>
    )
});

export default Catalogos;