import React, { useEffect, useCallback } from 'react';
import { Route } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from 'react-redux'
import * as familiasActions from '../../store/familias/familias.actions';
import FamilyNav from './../../components/FamiliaNav/FamiliaNav';
import Error from './../../components/Error/Error';
import ProductList from './../../components/ProductList/ProductList';
import Spinner from './../../core/Spinner/Spinner';
import Search from '../../components/Search/Search';
import Button from '../../core/Button/Button';
import './Products.scss';

const Products: React.FunctionComponent<any> = ({ match }) => {
    const { error, familias, fetching } = useSelector((state: any) => state.familiasStore);
    const dispatch = useDispatch();

    const fetchData = useCallback(() => {
        if (!familias.length) dispatch(familiasActions.requestFamilias());
    }, [dispatch, familias.length]);

    useEffect(() => fetchData(), [fetchData]);

    if (error) {
        return (
            <div className="ProductsError"><Error retry={fetchData}  /></div>
        );
    }

    if (fetching) {
        return (
            <div className="container ProductsContainer">
                <Spinner>Buscando productos y categorías...</Spinner>
            </div>
        );
    }

    const openModifications = () => {
        window.location.href = 'http://rthrepuestos.com.ar/ultmodif.pdf';
    };

    const exportArticulos = () => {
        window.location.href = 'http://rthrepuestos.com.ar/listacompleta.xls';
    };

    return (
        <div className="container">
            <Helmet>
                <title>RTH | Productos</title>
                <meta name="description" content="Distribuidor mayorista de respuestos y herramientas. Dedicados al agro, transporte, industria y herramientas. Repuesto automotor" />
                <meta name="keywords" content="distribuidora, traverso, rth, junin, ropa de trabajo, 3m, lubricantes y aditivos, filtros, correas, bombas de agua, baterias, herramientas, lamparas, mangueras y abrazaderas, depositos, termostatos, argentina, indumentaria, automotor, accesorios, distribucion, repuestos, automotriz, automotor, transporte, agro, industria, servicio, ricardo traverso, distribuidor mayorista" />
            </Helmet>
            <Button click={() => openModifications()} className="UltimaModificacion">ÚLTIMAS MODIFICACIONES</Button>
            <img className="xlsIcon" alt="RTH Repuestos" src="./assets/xls.png" onClick={() => exportArticulos()} />
            <div className="container ProductsContainer">
                <div className="ProductFilterContainer">
                    <Search />
                </div>
                <div className="FamiliasContainer">
                    <div className="FamiliaList">
                        <FamilyNav familias={familias} match={match} />
                    </div>
                </div>
                <div className="ProductListContainer">
                    <Route path={`${match.path}/`} component={ProductList}/>
                </div>
            </div>
        </div>
    );
};

export default Products;