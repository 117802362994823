import React, { useState, useEffect } from 'react';
import './Checkout.scss';
import { Helmet } from "react-helmet";
import { Pedclid, Pedclim } from '../../classes/pedido';
import { useSelector, useDispatch } from 'react-redux';
import { createPedido, resetPedidoFetch } from './../../store/pedidos/pedidos.actions';
import Error from './../../components/Error/Error';
import CartItem from '../../components/CartItem/CartItem';
import Button from '../../core/Button/Button';
import Currency from '../../core/Currency/Currency';
import Spinner from '../../core/Spinner/Spinner';

const Checkout: React.FunctionComponent<{}> = () => {
    const dispatch = useDispatch();
    const { cart } = useSelector((state: any) => state.cartStore);
    const { fetching, error, requestDone } = useSelector((state: any) => state.pedidosStore);
    const [ observaciones, setObservaciones ] = useState<string>('');
    const user = useSelector((state: any) => state.authStore.user);

    useEffect(() => {
        dispatch(resetPedidoFetch());
    }, [dispatch]);

    const getTotal = (): number => {
        let total: number = 0;

        for (let i = 0, N = cart.length; i < N; i++) total += cart[i].PVENTA;

        return Number(total.toFixed(2));
    };

    const getDate = (): string => {
	    const d = new Date(),
		    month = d.getMonth() + 1,
		    day = d.getDate(),
		    year = d.getFullYear();
	
		return `${year}-${month}-${day}`;
    };

    const takeOrder = () => {
        if (cart.length > 99) {
            alert('No puede pedir mas de 99 artículos. Por favor, separelo en distintos pedidos que no superen ese límite.');
            return;
        }

        const pedclim: Pedclim = new Pedclim(-1, '99', getDate(), user.CODCLI, getTotal(), observaciones, false, cart);
        dispatch(createPedido(pedclim));
    };

    const setObs = (evt: any) => {
        setObservaciones(evt.target.value);
    };

    const renderCart = () => {
        if (error) {
            return <Error msg="Hubo un error al tomar su pedido. Por favor intente nuevamente" />
        }
    
        if (fetching) {
            return <Spinner>Tomando pedido, por favor espere...</Spinner>;
        }
    
        if (requestDone) {
            return <p>Gracias por tomar su pedido. Nos comuncaremos con usted en breve.</p>
        }

        if (!cart || !cart.length) {
            return <p>No hay productos en el carrito</p>;
        }

        return (
            <div>
                <div className="CartList">
                    {cart.map((item: Pedclid, index: number) => <CartItem key={index} {...item} />)}
                </div>
                <p className="CartTotal">Compra total: <b><Currency value={getTotal()} /></b></p>
                <textarea 
                    placeholder="Observaciones" 
                    value={observaciones}
                    onChange={setObs}
                    className="Observaciones">
                </textarea>
                <div className="text-right">
                    <Button 
                        click={takeOrder} 
                        className="TomarPedidoBtn">TOMAR PEDIDO
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <div className="container SectionContainer Checkout">
            <Helmet>
                <title>RTH | Carrito para pedidos</title>
                <meta name="description" content="Distribuidor mayorista de respuestos y herramientas. Dedicados al agro, transporte, industria y herramientas. Repuesto automotor" />
                <meta name="keywords" content="distribuidora, traverso, rth, junin, ropa de trabajo, 3m, lubricantes y aditivos, filtros, correas, bombas de agua, baterias, herramientas, lamparas, mangueras y abrazaderas, depositos, termostatos, argentina, indumentaria, automotor, accesorios, distribucion, repuestos, automotriz, automotor, transporte, agro, industria, servicio, ricardo traverso, distribuidor mayorista" />
            </Helmet>
            <h3 className="SectionTitle">Carrito de compras</h3>
            {renderCart()}
        </div>
    );
};

export default Checkout;