import React from 'react';
import './Novedades.scss';
import { Helmet } from "react-helmet";
const Novedades: React.FC<{}> = React.memo(() => (
    <div className="container Novedades">
        <Helmet>
            <title>RTH | Novedades</title>
            <meta name="description" content="Distribuidor mayorista de respuestos y herramientas. Dedicados al agro, transporte, industria y herramientas. Repuesto automotor" />
            <meta name="keywords" content="distribuidora, traverso, rth, junin, ropa de trabajo, 3m, lubricantes y aditivos, filtros, correas, bombas de agua, baterias, herramientas, lamparas, mangueras y abrazaderas, depositos, termostatos, argentina, indumentaria, automotor, accesorios, distribucion, repuestos, automotriz, automotor, transporte, agro, industria, servicio, ricardo traverso, distribuidor mayorista" />
        </Helmet>
        <h3 className="SectionTitle">Novedades</h3>
        <div className="separator"></div>
        <ul>
            <li><a href="http://rthrepuestos.com.ar/ofertadelmes.pdf" target="_blank" rel="noopener noreferrer">Ofertas del mes</a></li>
            <li><a href="http://rthrepuestos.com.ar/equivfiltliv.xlsx" target="_blank" rel="noopener noreferrer">Equivalencias filtros livianos</a></li>
            <li><a href="http://rthrepuestos.com.ar/equivfiltpesa.xlsx" target="_blank" rel="noopener noreferrer">Equivalencias filtros pesados</a></li>
            <li><a href="http://rthrepuestos.com.ar/equivkitdist.xlsx" target="_blank" rel="noopener noreferrer">Equivalencias kit de distribución</a></li>
            <li><a href="http://rthrepuestos.com.ar/medfiltlan.xlsx" target="_blank" rel="noopener noreferrer">Medidas filtros Lanns</a></li>
            <li><a href="http://rthrepuestos.com.ar/equivbombas.xls" target="_blank" rel="noopener noreferrer">Equivalencias bombas de agua</a></li>
            <li><a href="http://rthrepuestos.com.ar/ctasbanc.pdf" target="_blank" rel="noopener noreferrer">Datos bancarios</a></li>
            <li><a href="http://rthrepuestos.com.ar/codigoqr.pdf" target="_blank" rel="noopener noreferrer">Código QR</a></li>
        </ul>
    </div>
));

export default Novedades;