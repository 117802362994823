import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import './NavList.scss';

export interface NavListItemMap {
    action?: any,
    activeClassName?: string,
    className?: any,
    exact?: boolean,
    label: string,
    to: string
};

export interface NavListMap {
    className?: any,
    items: NavListItemMap[]
};

const NavList: React.FunctionComponent<NavListMap> = React.memo(({ className = null, items = [] }) => (
    <ul className={classNames('NavList', className)}>
        {items.map((item: NavListItemMap, index: number) => (
            <li 
                onClick={item.action}
                key={index}>
                <NavLink 
                    activeClassName={item.activeClassName} 
                    className={classNames('NavListItem', item.className)}
                    exact={item.exact}
                    to={item.to}>{item.label}
                </NavLink>
            </li>
        ))}
    </ul>
));

export default NavList;