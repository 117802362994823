import React from 'react';
import ServicePresentationitem, { IServicePresentation } from './../ServicePresentationItem/ServicePresentationItem';

const ServicesPresentation: React.FunctionComponent<{}> = React.memo(() => {
    const services: IServicePresentation[] = [{ 
        title: '+40 años de trayectoria', 
        text: 'Nuestra experiencia nos permite ofrecer al cliente una rápida y personalizada atención de nuestro personal capacitado.',
        icon: 'TrayectoriaIcon'
    }, {
        title: 'Entrega en tiempo y forma',
        text: 'Nos destacamos por la eficiencia y responsabilidad a la hora de la salida y entrega de cada uno de sus pedidos.',
        icon: 'EntregaIcon'
    }, {
        title: 'Stock permanente',
        text: 'Un completo stock de mercadería nos permite cumplir con la totalidad del pedido.',
        icon: 'StockIcon'
    }];

    return (
        <div className="SectionsPresentation">
            <div className="container">
                <h3>Nuestros Servicios</h3>

                <div className="row">
                    {services.map((service: IServicePresentation, index: number) => (
                    <div 
                        className="col-md-4"
                        key={index}>
                        <ServicePresentationitem {...service} />
                    </div>
                ))}
                </div>
            </div>
        </div>
    );
});

export default ServicesPresentation;