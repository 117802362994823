import axios from 'axios';
import constants from './../../constants';
import { Pedclim, Pedclid } from './../../classes/pedido';
import { Dispatch } from 'react';
import { emptyCart } from '../cart/cart.actions';
import { getHeaders } from './../auth/auth.actions';

export const FETCH_PEDIDOS = 'FETCH_PEDIDOS';
export const FETCH_PEDIDOS_SUCCESS = 'FETCH_PEDIDOS_SUCCESS';
export const FETCH_PEDIDOS_ERROR = 'FETCH_PEDIDOS_ERROR';

export const SUBMIT_PEDIDO = 'SUBMIT_PEDIDO';
export const SUBMIT_PEDIDO_SUCCESS = 'SUBMIT_PEDIDO_SUCCESS';
export const SUBMIT_PEDIDO_ERROR = 'SUBMIT_PEDIDO_ERROR';

export const RESET_PEDIDO_FETCH = 'RESET_PEDIDO_FETCH';

export const resetPedidoFetch = () => ({ type: RESET_PEDIDO_FETCH });

const fetchPedidos = () => ({ type: FETCH_PEDIDOS });
const fetchPedidosError = () => ({ type: FETCH_PEDIDOS_ERROR });
const fetchPedidosSuccess = (pedclims: Pedclim[]) => ({ pedclims, type: FETCH_PEDIDOS_SUCCESS });

const submitPedido = () => ({ type: SUBMIT_PEDIDO });
const submitPedidoError = () => ({ type: SUBMIT_PEDIDO_ERROR });
const submitPedidoSuccess = () => ({ type: SUBMIT_PEDIDO_SUCCESS });

export const getPedidos = (): Dispatch<any> => (dispatch: Dispatch<any>) => {
    dispatch(fetchPedidos());

    const headers = getHeaders();

    if (!headers) {
        dispatch(fetchPedidosError());
        return;
    }

    return axios.get(`${constants.server}/pedidos`, { headers })
        .then((response: any) => {
            const pedidos: Pedclim[] = response.data.map((pedido: Pedclim) => {
                pedido.PEDCLIDS = pedido.PEDCLIDS.map(pd => new Pedclid(pd.NPEDIDO, pd.CODART, pd.CANT_PED, pd.PVENTA, '', 0));
                return new Pedclim(
                    pedido.NPEDIDO,
                    pedido.CVIAJ,
                    pedido.FMOV,
                    pedido.CODCLI,
                    pedido.TOTAL,
                    pedido.OBSER,
                    pedido.BAJADO,
                    pedido.PEDCLIDS
                );
            });
            dispatch(fetchPedidosSuccess(pedidos));
        })
        .catch(() => dispatch(fetchPedidosError()));
};

export const createPedido = (pedclim: Pedclim): Dispatch<any> => (dispatch: Dispatch<any>) => {    
    dispatch(submitPedido());

    const headers = getHeaders();

    if (!headers) {
        dispatch(submitPedidoError());
        return;
    }

    return axios.post(`${constants.server}/pedido`, pedclim, { headers })
        .then(() => {
            dispatch(submitPedidoSuccess());
            dispatch(emptyCart());
            localStorage.removeItem('rthMagCart');
        })
        .catch(() => dispatch(submitPedidoError()));
};
