import React from 'react';
import { Helmet } from "react-helmet";
import Slider from './../../components/Slider/Slider';
import ProductsPresentation from './../../components/ProductsPresentation/ProductsPresentation';
import ServicesPresentation from './../../components/ServicesPresentation/ServicesPresentation';

const Home: React.FunctionComponent<{}> = React.memo(() =>(
    <div>
        <Helmet>
            <title>RTH | Ricardo Traverso E Hijo SRL</title>
            <meta name="description" content="Distribuidor mayorista de respuestos y herramientas. Dedicados al agro, transporte, industria y herramientas. Repuesto automotor" />
            <meta name="keywords" content="distribuidora, traverso, rth, junin, ropa de trabajo, 3m, lubricantes y aditivos, filtros, correas, bombas de agua, baterias, herramientas, lamparas, mangueras y abrazaderas, depositos, termostatos, argentina, indumentaria, automotor, accesorios, distribucion, repuestos, automotriz, automotor, transporte, agro, industria, servicio, ricardo traverso, distribuidor mayorista" />
        </Helmet>
        <Slider />
        <ProductsPresentation />
        <ServicesPresentation />
    </div>
));

export default Home;