import React from 'react';
import './ServicePresentationItem.scss';

export interface IServicePresentation {
    text: string,
    title: string,
    icon: string
};

const ServicePresentationitem: React.FunctionComponent<IServicePresentation> = ({ text, title, icon }) => {
    return (
        <div className="ServicePresentationItem">
            <div className="IconContainer">
                <span className={icon}></span>
            </div>
            <h5 className="text-center">{title}</h5>
            <p>{text}</p>
        </div>
    );
};

export default ServicePresentationitem;