import React, { useState } from 'react';
import { Familia, Subfamilia } from './../../classes/familia';
import './FamiliaNav.scss';
import { useHistory, useLocation } from 'react-router-dom';
const FamiliaNav: React.FunctionComponent<{ familias: Familia[], match: any }> = React.memo(({ familias = [], match }) => {
    const [ selectedFamilia, setSelectedFamilia ] = useState<number>();
    const [ selectedSubfamilia, setSelectedSubfamilia ] = useState<number>(-1);
    const history = useHistory();
    const location = useLocation();
    
    const goToFamilia = (evt: any) => {
        const ID_FLIA: number = Number(evt.target.value);
        setSelectedFamilia(ID_FLIA);
        setSelectedSubfamilia(-1);

        const searchParams = new URLSearchParams(location.search);
        searchParams.set('familia', ID_FLIA.toString());
        searchParams.delete('subfamilia');

        const newSearchString = searchParams.toString();

        history.push(`${match.url}?${newSearchString}`);
    }

    const goToSubfamilia = (evt: any) => {
        const searchParams = new URLSearchParams(location.search);
        const ID_SUBF: number = Number(evt.target.value);
        setSelectedSubfamilia(ID_SUBF);
        searchParams.set('subfamilia', ID_SUBF.toString());
        const newSearchString = searchParams.toString();
        history.push(`${match.url}?${newSearchString}`);
    };

    const renderSubfamiliasOptions = () => {
        const options: any[] = [<option key={-1} value="-1">Elegir</option>];
        const familia = familias.find((f: Familia) => f.ID_FLIA === selectedFamilia);
        
        if (familia && familia.SUBFAMILIAS) {
            familia.SUBFAMILIAS.forEach((subfamilia: Subfamilia, i: number) => {
                options.push(<option key={i} value={subfamilia.ID_SUBF}>{subfamilia.NSUBF}</option>);
            });
        }

        return options;       
    }

    return (
        <div className="FamiliaDropdown">
            <div>
                <h3>Familias</h3>
                <select className="form-control" onChange={goToFamilia}>
                    <option value="null">Elegir</option>
                    {familias.map((familia: Familia, index: number) => (<option key={index} value={familia.ID_FLIA}>{familia.NFAMILIA}</option>))}
                </select>
            </div>
            <div>
                <h3 className="subfamilias-title">Subfamilias</h3>
                <select value={selectedSubfamilia} className="form-control" onChange={goToSubfamilia}>                
                    {renderSubfamiliasOptions()}
                </select>
            </div>
        </div>
    );
});

export default FamiliaNav;