import { updateState } from './../helpers';
import { 
    FORGOT_PASSWORD_ERROR,
    FORGOT_PASSWORD_START,
    FORGOT_PASSWORD_SUCCESS,
    LOGIN_ERROR,
    LOGIN_START,
    LOGIN_SUCCESS,
    RESET_AUTH_STATE,
    INVALID_USER
} from './auth.actions';

const initialState = { 
    fetchError: false,
    fetching: false,
    forgotRequested: false,
    token: '',
    invalidReason: '',
    user: null
};

export default (state = initialState, action: any = {}) => {
    switch (action.type) {
        case LOGIN_START: return updateState(state, { 
            fetchError: false, 
            fetching: true, 
            token: '',
            invalidReason: '', 
            user: null 
        });
        case LOGIN_ERROR: return updateState(state, { 
            fetchError: true, 
            fetching: false, 
            token: '',
            invalidReason: '', 
            user: null 
        });
        case LOGIN_SUCCESS: return updateState(state, { 
            fetchError: false,
            fetching: false, 
            token: action.token,
            invalidReason: '', 
            user: action.user 
        });
        case FORGOT_PASSWORD_START: return updateState(state, { fetchError: false, fetching: true, forgotRequested: false });
        case FORGOT_PASSWORD_ERROR: return updateState(state, { fetchError: true, fetching: false, forgotRequested: true });
        case FORGOT_PASSWORD_SUCCESS: return updateState(state, { fetchError: false, fetching: false, forgotRequested: true });
        case INVALID_USER: return updateState(state, { 
            fetchError: false, 
            fetching: false, 
            token: '',
            invalidReason: action.reason, 
            user: null 
        });
        case RESET_AUTH_STATE: return initialState;
        default: return state;
    };
};