import React from 'react';
import './ProductsPresentation.scss';
import ProductPresentationItem from '../ProductPresentationItem/ProductPresentationItem';
import { Helmet } from "react-helmet";

const ProductsPresentation: React.FunctionComponent<{}> = React.memo(() => {
    const imgUrls: string = './assets/products-index';
    const mainProducts = [
        { title: 'Lubricantes y aditivos', img: `${imgUrls}/1.jpg` },
        { title: 'Filtros', img: `${imgUrls}/2.jpg` },
        { title: 'Correas, Tensores y Bombas de agua', img: `${imgUrls}/3.jpg` },
        { title: 'Baterías', img: `${imgUrls}/4.jpg` },
        { title: 'Correas industriales y agrícolas', img: `${imgUrls}/5.jpg` },
        { title: 'Herramientas', img: `${imgUrls}/6.jpg` },
        { title: 'Encendido e ignición', img: `${imgUrls}/7.jpg` },
        { title: 'Lámparas', img: `${imgUrls}/8.jpg` },
        { title: 'Mangueras y abrazaderas', img: `${imgUrls}/9.jpg` },
        { title: 'Depósitos, tapas y termostatos', img: `${imgUrls}/10.jpg` },
        { title: 'Indumentaria de trabajo', img: `${imgUrls}/11.jpg` },
        { title: 'Cosmética automotor y accesorios', img: `${imgUrls}/12.jpg` }
    ];

    return (
        <div className="SectionsPresentation ProductsPresentation">
            <Helmet>
                <meta name="description" content="Distribuidor mayorista de respuestos y herramientas. Dedicados al agro, transporte, industria y herramientas. Repuesto automotor" />
                <meta name="keywords" content="distribuidora, traverso, rth, junin, ropa de trabajo, 3m, lubricantes y aditivos, filtros, correas, bombas de agua, baterias, herramientas, lamparas, mangueras y abrazaderas, depositos, termostatos, argentina, indumentaria, automotor, accesorios, distribucion, repuestos, automotriz, automotor, transporte, agro, industria, servicio, ricardo traverso, distribuidor mayorista" />
            </Helmet>
            <div className="container">
                <h3>Algunos de nuestros productos</h3>

                <div className="row">
                    {mainProducts.map((product, index: number) => (
                        <div 
                            key={index}
                            className="col-md-6 col-lg-3">
                            <ProductPresentationItem product={product} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
});

export default ProductsPresentation;