import React from 'react';
import { Helmet } from "react-helmet";
import './About.scss';

const About: React.FC<{}> = React.memo(() => (
    <div className="About">
        <Helmet>
            <title>RTH | Empresa</title>
            <meta name="description" content="Distribuidor mayorista de respuestos y herramientas. Dedicados al agro, transporte, industria y herramientas. Repuesto automotor" />
            <meta name="keywords" content="distribuidora, traverso, rth, junin, ropa de trabajo, 3m, lubricantes y aditivos, filtros, correas, bombas de agua, baterias, herramientas, lamparas, mangueras y abrazaderas, depositos, termostatos, argentina, indumentaria, automotor, accesorios, distribucion, repuestos, automotriz, automotor, transporte, agro, industria, servicio, ricardo traverso, distribuidor mayorista" />
        </Helmet>
        <div className="container">
            <h3 className="SectionTitle">Bienvenidos a nuestra empresa.</h3>
            <p className="MainDescription">Somos una empresa familiar argentina dedicada a la distribución y venta de repuestos para el sector automotriz, el agro, el transporte y la industria; así como también de herramientas.</p>
            <p>Nuestro objetivo es brindar un rápido y eficaz servicio a la hora de solucionar las necesidades y urgencias del cliente; tanto a través de la variedad y calidad de nuestros productos como de la calidez y dedicación de nuestro personal.</p>

            <h4 className="SubTitle">Nuestra historia</h4>
            <div className="separator"></div>
            <p>La empresa comienza sus actividades en el año 1980 en un garaje de la calle Dr. Benito de Miguel 875, actual ubicación, dedicándose a la venta al público de repuestos automotor y agrícola. Esta primera etapa se inició de la mano de Ricardo Rubén Traverso, su esposa Aida Leonor Duhalde y su hijo Guillermo Rubén Traverso.</p>
            <p>En el año 2006, luego del fallecimiento de su fundador, la empresa adopta su denominación actual, Ricardo Traverso e Hijo SRL, con sus socios gerentes Guillermo Rubén Traverso y Velazco María Cecilia.<br />
            Para el año 2016, con el ingreso de los hijos de los socios gerentes: Franco Traverso, Genaro Traverso y Emiliano Traverso, se inicia la distribuidora con el objetivo de abastecer al noroeste de la provincia de Buenos Aires y alrededores.
            </p>
        </div>

        <div className="HighlightSection">
            <div className="container">
                <div className="HighlightItem">
                    <h5>Nuestra visión</h5>
                    <p>Ser la mejor opción para cada casa de repuesto y cada cliente particular.</p>
                </div>
                <div className="HighlightItem">
                    <h5>Nuestra misión</h5>
                    <p>Ofrecer a nuestros clientes una variada y competitiva carpeta de productos; así como también una rápida y eficiente atención; para que el repuestero tenga la posibilidad de desarrollar su emprendimiento de manera conjunta y el cliente pueda encontrar lo que necesita.</p>
                </div>
                <div className="HighlightItem">
                    <h5>Nuestros valores</h5>
                    <p>Defendemos y promulgamos estos valores: INTEGRIDAD - HONESTIDAD - PERSEVERANCIA - LEALTAD - TRABAJO EN EQUIPO - EL ORDEN - INTERES POR LA GENTE - LA EXCELENCIA EN EL SERVICIO - RESPETO POR LA DIVERSIDAD - LA RESPONSABILIDAD SOCIAL.</p>
                </div>
            </div>
        </div>
    </div>
));

export default About;