import React from 'react';
import './ProductPresentationItem.scss';

const ProductPresentationItem: React.FunctionComponent<{ product: any }> = React.memo(({ product }) => {
    const title: string = product.title;

    return (
        <div className="ProductPresentationItem text-center">
            <img 
                alt={title}
                src={product.img} 
            />

            <h6>{title.toUpperCase()}</h6>
        </div>
    );
});

export default ProductPresentationItem;