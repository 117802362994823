import React from 'react';
import AuthNav from './../AuthNav/AuthNav';
import CommonNav from '../CommonNav/CommonNav';
import './Header.scss';

const Header: React.FunctionComponent = React.memo(() => (
    <header>
        <CommonNav />
        <AuthNav />
    </header>
));

export default Header;