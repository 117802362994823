import { Pedclid } from '../../classes/pedido';
import { 
    EMPTY_CART,
    UPDATE_CART,
    REMOVE_FROM_CART
} from './cart.actions';

interface ICartState {
    cart: Pedclid[]
};

const initialState: ICartState = {
    cart: JSON.parse(localStorage.getItem('rthMagCart') || '[]')
};

export default (state = initialState, action: any = {}) => {
    switch (action.type) {
        case REMOVE_FROM_CART: {
            const cartCopy: Pedclid[] = state.cart.filter((pd: Pedclid) => pd.CODART !== action.CODART);
            localStorage.setItem('rthMagCart', JSON.stringify(cartCopy));
            return { cart: cartCopy };
        }
        case UPDATE_CART: {
            const pedclid: Pedclid = action.pedclid;
            const CODART: string = pedclid.CODART;
            const articuloInCart: number = state.cart.findIndex((pd: Pedclid) => pd.CODART === CODART);
            const cartCopy: Pedclid[] = [...state.cart];

            if (articuloInCart > -1) {
                cartCopy.splice(articuloInCart, 1, pedclid);
            } else {
                cartCopy.push(pedclid);
            }

            localStorage.setItem('rthMagCart', JSON.stringify(cartCopy));
            return { cart: cartCopy };
        }
        case EMPTY_CART: return { cart: [] };
        default: return state;
    }
};