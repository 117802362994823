import axios from 'axios';
import constants from './../../constants';
import Articulo from '../../classes/articulo';
import { Dispatch } from 'react';
import { getHeaders } from './../auth/auth.actions';
export const FETCH_ARTICULOS = 'FETCH_ARTICULOS';
export const FETCH_ARTICULOS_SUCCESS = 'FETCH_ARTICULOS_SUCCESS';
export const FETCH_ARTICULOS_ERROR = 'FETCH_ARTICULOS_ERROR';

export interface ArticulosParams {
    familia?: number,
    subfamilia?: number,
    filter?: string
}
const fetchArticulos = () => ({ type: FETCH_ARTICULOS });
const fetchArticulosError = () => ({ type: FETCH_ARTICULOS_ERROR });
const fetchArticulosSuccess = (articulos: Articulo[]) => ({ articulos, type: FETCH_ARTICULOS_SUCCESS });

export const requestArticulosByCode = (codes: string[] = []) => (dispatch: Dispatch<any>) => dispatch(getArticulosByCode(codes));
export const requestArticulos = ({ 
    familia = 0, 
    subfamilia = 0, 
    filter = '' 
}: ArticulosParams) => (dispatch: Dispatch<any>) => dispatch(getArticulos({ familia, subfamilia, filter }));


const getArticulos = ({ familia = 0, subfamilia = 0, filter = '' } = {}) => (dispatch: Dispatch<any>) => {
    dispatch(fetchArticulos());

    let queryParams: string = '';

    if (filter) {
        queryParams = `filter=${filter}&`;
    } 
    
    if (familia) {
        queryParams += `familia=${familia}`
        queryParams += subfamilia ? `&subfamilia=${subfamilia}` : '';
    }
    
    const headers = getHeaders();

    if (!headers) {
        dispatch(fetchArticulosError());
        return;
    }

    return axios.get(`${constants.server}/articulos?${queryParams}`, { headers })
        .then((response: any) => dispatch(fetchArticulosSuccess(response.data)))
        .catch(() => dispatch(fetchArticulosError()));
};

const getArticulosByCode = (codes: string[] = []) => (dispatch: Dispatch<any>) => {
    dispatch(fetchArticulos());
    const headers = getHeaders();

    if (!headers) {
        dispatch(fetchArticulosError());
        return;
    }
    
    return axios.get(`${constants.server}/articulosByCode`, { headers, params: { codes } })
        .then((response: any) => dispatch(fetchArticulosSuccess(response.data)))
        .catch(() => dispatch(fetchArticulosError()));
};
