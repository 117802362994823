import React from 'react';
import { Helmet } from "react-helmet";
import * as authActions from '../../store/auth/auth.actions';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Form, { FormElementMap } from './../../components/Form/Form';
import Error from './../../components/Error/Error';
import Spinner from '../../core/Spinner/Spinner';

const Forgot: React.FunctionComponent<{}> = () => {
    const dispatch = useDispatch();
    const { error, fetching, forgotRequested } = useSelector((state: any) => state.authStore);

    const onSubmit = (credentials: any) => {
        dispatch(authActions.requestPasswordReset({ credentials }));
    };

    const renderForm = () => {
        if (error) {
            return <Error />;
        }

        if (forgotRequested) {
            alert('Le hemos enviado un email para reiniciar su contraseña');
            return <Redirect to="/" />
        }

        if (fetching) {
            return <Spinner>Corroborando credenciales...</Spinner>
        }

        const formElements: FormElementMap[] = [{ 
            elementType: 'input', 
            id: 'E_MAIL', 
            placeholder: 'Correo Electrónico', 
            valueType: 'email', 
            required: true, 
            minLength: 8 
        }, { 
            elementType: 'input', 
            id: 'CUITCLI', 
            placeholder: 'CUIT con guiones (-)', 
            valueType: 'text', 
            required: true, 
            minLength: 13,
            maxLength: 13 
        }];

        return (
            <Form 
                onSubmit={onSubmit}
                elements={formElements}
                name="authForm"
                submitText="Solicitar cambio de contraseña"
            />
        );
    };

    return (
        <div className="container SectionContainer">
            <Helmet>
                <title>RTH | Olvidé mi contraseña</title>
                <meta name="description" content="Distribuidor mayorista de respuestos y herramientas. Dedicados al agro, transporte, industria y herramientas. Repuesto automotor" />
                <meta name="keywords" content="distribuidora, traverso, rth, junin, ropa de trabajo, 3m, lubricantes y aditivos, filtros, correas, bombas de agua, baterias, herramientas, lamparas, mangueras y abrazaderas, depositos, termostatos, argentina, indumentaria, automotor, accesorios, distribucion, repuestos, automotriz, automotor, transporte, agro, industria, servicio, ricardo traverso, distribuidor mayorista" />
            </Helmet>
            <h3 className="SectionTitle">Olvidé mi contraseña</h3>
            <div className="AuthFormContainer">{renderForm()}</div>
        </div>
    );
};

export default Forgot;